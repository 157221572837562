'use strict';

window.addEventListener('livewire-form:scroll-to', (ev) => {
    ev.stopPropagation();

    const selector = ev?.detail?.query;

    if (!selector) {
        return;
    }

    const errorFields = window.document.querySelectorAll(selector);
    let firstVisibleErrorField = null;
    for(var i in errorFields) {
        if(errorFields[i].innerHTML.trim() != '') {
            firstVisibleErrorField = errorFields[i];
            break;
        }
    }

    if (!firstVisibleErrorField) {
        return;
    }

    try {
        var elemY = firstVisibleErrorField.getBoundingClientRect().top + window.scrollY - 120;
        window.scrollTo({top: elemY, behavior: 'smooth'});

        /*el.scrollIntoView({
            behavior: 'smooth',
        });*/
    } catch {}

}, false);

